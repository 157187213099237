import { useAuth0 } from '@auth0/auth0-react';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Dummy site, which just redirects user to Auth0 login
const LoginPage = () => {
  const { i18n } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect({ ui_locales: i18n.language });
  }, [loginWithRedirect, i18n]);

  return <div />;
};

export default memo(LoginPage);
